<template>
    <div class="mx-auto" style="max-width:400px;">
         <v-card
            class="pa-2 mt-4"
            color="#212121"
            elevation="4"
            dark>

            <div class="text-center caption primary--text font-weight-bold">
                ליצירת הקדשה מלאו את הפרטים הבאים
            </div>

            <v-select
                class="mt-4"
                :items="items"
                label="מוקדש ל"
                color="primary"
                v-model="selected"
                dense
                hide-details
                outlined>
            </v-select>

            <v-text-field
                class="mt-2"
                label="פלוני/ת בן/ת פלוני/ת"
                v-model="name"
                outlined
                dense
                hide-details
                color="primary">
            </v-text-field>

            <v-btn
                class="primary--text mt-2 mx-auto"
                color="white"
                dark
                v-if="!showShare"
                :disabled="(selected.length && name.length) ? false : true"
                @click="createLink"
                block>
                יצירת הקדשה
            </v-btn>
            <v-btn
                class="primary--text mt-2 mx-auto"
                color="white"
                dark
                v-else
                @click="createNew"
                block>
                יצירת הקדשה חדשה
            </v-btn>

            

            <div v-if="showShare">
                <v-divider class="mt-3 mx-auto"></v-divider>
                <v-text-field
                    label="לינק להקדשה"
                    color="primary"
                    class="mt-4 white--text"
                    background-color="black"
                    filled
                    dense
                    dark
                    outlined
                    hide-details="true"
                    v-model="share.url"
                    prepend-inner-icon="mdi-link-variant"
                    readonly>
                </v-text-field>

                <v-btn
                    class="mt-4 white--text"
                    color="primary"
                    @click="copyToClipboard"
                    block>
                    <v-icon left>
                        mdi-content-copy
                    </v-icon>
                    העתקת לינק
                </v-btn>
                <div class="mt-4 text-center caption">לשיתוף הקדשה 
                    <strong class="primary--text">ל{{ selected }} {{ name }}</strong>

                </div>
                <div>
                    <ShareDedicated v-if="showShare" :shareData="share"/>
                </div>
            </div>

          </v-card>

          <v-snackbar
            v-model="snackbar"
            content-class="mx-auto text-center"
            width="1"
            color="primary"
            timeout="2000">
            <v-icon right>
                mdi-check-circle 
            </v-icon>
            לינק הועתק!
        </v-snackbar>
    </div>
</template>

<script>
import ShareDedicated from './App/ShareDedicated.vue'

export default {
    props: ['prayer'],
    data() {
        return {
            share: {
                title: '',
                description: '',
                url: ''
            },
            items: [
                'עילוי נשמת',
                'רפואת',
                'הצלחת'
            ],
            name: '',
            selected: '',
            snackbar: false,
            showShare: false
        }
    },
    methods: {
        createLink() {
            this.showShare = false
            let type = this.getTypeNumber()
            let id = this.$route.params.id
            let nameTo = this.name.replaceAll(' ', '%20')
            this.share.title = this.prayer.name
            this.share.description = 'מוקדש ל' + this.selected + ' ' + this.name 
            this.share.url = 'https://AmIsraeLive.com/prayer/' + id + '/dedicated/' + type + '/to/' + nameTo
            this.showShare = true
        },
        createNew() {
            this.showShare = false
            this.selected = ''
            this.name = ''
        },
        getTypeNumber() {
            switch(this.selected) {
                case 'עילוי נשמת':
                    return '1'
                case 'רפואת':
                    return '2'
                case 'הצלחת':
                    return '3'
                default:
                    return ''
            }
        },
        copyToClipboard() {
            navigator.clipboard.writeText(this.share.url)
            this.snackbar = true
        },
    },
    components: { 
        ShareDedicated 
    },
}

</script>